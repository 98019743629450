import React from 'react';
import ReactDOM from 'react-dom/client';
import ErrorBoundary from '@/components/ErrorBoundary';

import './global.css';

export default function createPage(PageComponent: React.ComponentType) {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    // <React.StrictMode>
    <ErrorBoundary>
      <PageComponent />
    </ErrorBoundary>
    // </React.StrictMode>
  );
}
