import React from 'react';
import { getText } from '@/common/language';

import styels from './index.module.less';
import like from '@/static/like.png';
import collect from '@/static/collect.png';
import read from '@/static/read.png';
import { replacePage } from '@/common/router';

export default function Rank() {
  const handleJumpRankPage = (type: string, workDirection: string) => {
    replacePage(`/rank.html?type=${type}&workDirection=${workDirection}`);
  };

  return (
    <div className={styels.rank}>
      <article className={styels.article}>
        <span className={styels.title}>{getText('男频分类')}</span>
        <div className={styels.typeWarp}>
          <section
            onClick={() => handleJumpRankPage('visit', '0')}
            className={styels.section}
          >
            <div className={styels.label}>{getText('阅读')}</div>
            <div className={styels.action}>
              <img src={read} alt="" />
              <div>{getText('查看榜单')}</div>
            </div>
          </section>
          <section
            onClick={() => handleJumpRankPage('update', '0')}
            className={styels.section}
          >
            <div className={styels.label}>{getText('更新')}</div>
            <div className={styels.action}>
              <img src={like} alt="" />
              <div>{getText('查看榜单')}</div>
            </div>
          </section>
          <section
            onClick={() => handleJumpRankPage('collect', '0')}
            className={styels.section}
          >
            <div className={styels.label}>{getText('收藏')}</div>
            <div className={styels.action}>
              <img src={collect} alt="" />
              <div>{getText('查看榜单')}</div>
            </div>
          </section>
        </div>
      </article>
      <article className={styels.article}>
        <span className={styels.title}>{getText('女频分类')}</span>
        <div className={styels.typeWarp}>
          <section
            onClick={() => handleJumpRankPage('visit', '1')}
            className={styels.section}
          >
            <div className={styels.label}>{getText('阅读')}</div>
            <div className={styels.action}>
              <img src={read} alt="" />
              <div>{getText('查看榜单')}</div>
            </div>
          </section>
          <section
            onClick={() => handleJumpRankPage('update', '1')}
            className={styels.section}
          >
            <div className={styels.label}>{getText('更新')}</div>
            <div className={styels.action}>
              <img src={like} alt="" />
              <div>{getText('查看榜单')}</div>
            </div>
          </section>
          <section
            onClick={() => handleJumpRankPage('collect', '1')}
            className={styels.section}
          >
            <div className={styels.label}>{getText('收藏')}</div>
            <div className={styels.action}>
              <img src={collect} alt="" />
              <div>{getText('查看榜单')}</div>
            </div>
          </section>
        </div>
      </article>
    </div>
  );
}
