import React from 'react';
import { DotLoading } from 'antd-mobile';
import styels from './index.module.less';

export default function Loading() {
  return (
    <div className={styels.loading}>
      <DotLoading style={{ color: '#FCC800' }} />
    </div>
  );
}
