import { IQueryBookParams } from '@/types/book';
import { getRequest, request } from './api';
import { errorCode } from '@/common/constant';

export const queryBookInfo = (id: string) => {
  return getRequest(`front/book/${id}`);
};

// 查询新书榜单
export const queryNewestRank = async () => {
  try {
    return getRequest('front/book/newest_rank');
  } catch (e) {
    return null;
  }
};

// 查询推荐榜单
export const queryRecList = async (workDirection: string) => {
  try {
    return getRequest('front/home/books', { workDirection });
  } catch (e) {
    return null;
  }
};

// 查询某本书关联的书
export const queryRecBook = async (bookId: string) => {
  try {
    const result = await getRequest('front/book/rec_list', { bookId });
    return result;
  } catch (e) {
    //ignore
    return [];
  }
};

// 查询分类
export const queryCategoryList = async (workDirection: string) => {
  return getRequest('front/book/category/list', { workDirection });
};

// 小说搜索接口
export const queryBookByCondition = async (
  params: IQueryBookParams
): Promise<any> => {
  try {
    return request('front/search/books', params);
  } catch (e) {
    return null;
  }
};

// 小说章节查询接口
export const queryChapterData = async (chapterId: string) => {
  try {
    const data = await getRequest(
      `front/book/content/${chapterId}`,
      undefined,
      {
        commonErrorCatch: true,
      }
    );
    return data;
  } catch (e) {
    console.log('ccccc', e);

    if (e === errorCode.NOT_HAVE_PREMISSION) {
      return 'HasNoPermissions';
    }
    return null;
  }
};

// 小说章节查询接口
export const queryBookChapterList = async (params: {
  bookId: string;
  pageNum: number;
  pageSize: number;
}) => {
  try {
    return getRequest(`front/book/chapter/list`, params, {
      commonErrorCatch: true,
    });
  } catch (e) {
    return null;
  }
};
