import React, { useState, useEffect } from 'react';
import { Popup, Input, Empty } from 'antd-mobile';
import { useRequest } from 'ahooks';
import { getText } from '@/common/language';
import styels from './index.module.less';
import { replacePage } from '@/common/router';
import Loading from '../Loading';
import { queryBookByCondition } from '@/service/book';
import { IBook, IQueryBookParams } from '@/types/book';
import VerticalBook from '../VerticalBook';
import Logo from '../Logo';

const queryData = async (value: string, defaultParams?: IQueryBookParams) => {
  if (!value) return null;
  const result = await queryBookByCondition({
    ...(defaultParams || {}),
    keyword: value,
    pageSize: 50,
  });
  return result?.list || [];
};

export default function Serach(params: { queryData?: IQueryBookParams }) {
  const [showPop, setShowPop] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const { data, loading, run } = useRequest(queryData, {
    debounceWait: 1000,
    manual: true,
  });

  useEffect(() => {
    run(searchValue, params.queryData);
  }, [params.queryData, run, searchValue]);

  return (
    <>
      <header className={styels.header}>
        <div onClick={() => setShowPop(true)} className={styels.searchDiv} />
        {/* <div className="myIcon" /> */}
        <div
          onClick={() => replacePage('/bookshelf.html')}
          className={styels.bookshelf}
        />
      </header>
      <Popup bodyClassName={styels.searchModal} visible={showPop}>
        <>
          <Logo />
          <div className={styels.warp}>
            <Input
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
              className={styels.search}
              placeholder={getText('请输入查询内容')}
            />
            <div className={styels.calcel} onClick={() => setShowPop(false)}>
              {getText('取消')}
            </div>
          </div>
          <section className={styels.section}>
            {loading ? (
              <div>
                <Loading />
              </div>
            ) : (
              <div className={styels.bookList}>
                {!!data?.length &&
                  data?.map((book: IBook) => {
                    return (
                      <div key={book.bookName} className="bookWarp">
                        <VerticalBook data={book} />
                      </div>
                    );
                  })}
                {data?.length === 0 && (
                  <div className={styels.noData}>
                    <Empty />
                    <div>{getText('暂时没有相关内容')}</div>
                  </div>
                )}
              </div>
            )}
          </section>
        </>
      </Popup>
    </>
  );
}
