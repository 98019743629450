import axios, { AxiosRequestConfig } from 'axios';
import { Toast } from 'antd-mobile';
import { getUserCookie } from '@/common/storage';
import { errorCode } from '@/common/constant';
import { getLanguage, getText } from '@/common/language';

interface ExtendedAxiosRequestConfig<D = any> extends AxiosRequestConfig<D> {
  commonErrorCatch?: boolean;
  needLogin?: boolean;
  needLoginPage?: boolean;
}

const instance = axios.create({
  timeout: 15000,
  baseURL: '/api/',
});

const token = getUserCookie('token');

instance.defaults.headers.common['Authorization'] = token;
instance.defaults.headers.common['Language'] = getLanguage();

instance.interceptors.response.use(
  (response) => {
    // 登录失效
    if (
      response?.data?.code === errorCode.LOGIN_INVALIDATION &&
      (response.config as ExtendedAxiosRequestConfig).needLogin
    ) {
      // window.location.replace('/login.html');
      return Promise.reject(errorCode.LOGIN_INVALIDATION);
    }
    if (response?.data?.ok === false) {
      return Promise.reject(response?.data?.code);
    }
    return response?.data?.data;
  },
  (error) => {
    const config = error.config as ExtendedAxiosRequestConfig;
    if (config?.commonErrorCatch) {
      // 其他报错
      Toast.show(getText('现在太多人啦> <请稍后再试'));
      return null;
    }

    return Promise.reject(error);
  }
);

instance.interceptors.request.use((queryData) => {
  let finalData = queryData;
  if (queryData?.data) {
    const tempData: Record<string, any> = {};
    Object.keys(queryData?.data).forEach((key) => {
      if (queryData?.data?.[key]) {
        tempData[key] = queryData?.data?.[key];
      }
    });
    finalData = { ...queryData, data: tempData };
  }
  return finalData;
});

export async function getRequest(
  url: string,
  data?: any,
  config: ExtendedAxiosRequestConfig = {}
): Promise<any> {
  let tempUrl = url;
  if (typeof data === 'object') {
    let params = Object.keys(data)
      .map((key) => {
        return `${key}=${encodeURIComponent(data[key])}`;
      })
      .join('&');
    if (url.indexOf('?') > -1) {
      tempUrl = `${tempUrl}&${params}`;
    } else {
      tempUrl = `${tempUrl}?${params}`;
    }
  }
  return instance.get(tempUrl, config);
}

export async function request(
  url: string,
  params?: any,
  config: ExtendedAxiosRequestConfig = {}
) {
  return instance.post(url, params, config);
}

export function updateCookie() {
  const token = getUserCookie('token');

  instance.defaults.headers.common['Authorization'] = token;
}
