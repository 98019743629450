import React from 'react';

import { IBook } from '@/types/book';
import { replacePage } from '@/common/router';

import styels from './index.module.less';

export default function HorizontalBook(props: { data: IBook }) {
  const { data } = props;
  const handleBookInfo = () => {
    replacePage(`./book.html?bookId=${data?.id || data?.bookId}`);
  };
  if (!data) return null;
  return (
    <div className={styels.bookWarp} onClick={handleBookInfo}>
      <img src={data.picUrl} alt="img" />
      <p className={styels.bookWarpTitle}>{data.bookName}</p>
      <p
        className={styels.bookWarpSubtitle}
        dangerouslySetInnerHTML={{ __html: data?.bookDesc }}
      ></p>
    </div>
  );
}
