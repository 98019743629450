import React, { useState } from 'react';
import { getText } from '@/common/language';
import { useAsyncEffect } from '@/common/hook';
import { queryCategoryList } from '@/service/book';
import { replacePage } from '@/common/router';
import book1 from '@/static/xuanhuan.png';
import book2 from '@/static/lingyi.png';
import book3 from '@/static/dushi.png';
import book4 from '@/static/kehuan.png';
import book5 from '@/static/fanzui.png';
import book6 from '@/static/lishi.png';
import book7 from '@/static/tiyu.png';
import book8 from '@/static/junshi.png';
import book9 from '@/static/duanpian.png';
import book10 from '@/static/xingzhen.png';
import nv1 from '@/static/nvxianyan.png';
import nv2 from '@/static/nvdanmei.png';
import nv3 from '@/static/nvguyan.png';
import nv4 from '@/static/nvxiaoyuan.png';
import nv5 from '@/static/nvqihuan.png';
import nv6 from '@/static/nvduanpian.png';
import styels from './index.module.less';

interface IClassify {
  key: string;
  title: string;
  data: {
    id: string;
    name: string;
    img: string;
  }[];
}

const manImgList = [
  book1,
  book2,
  book3,
  book4,
  book5,
  book6,
  book7,
  book8,
  book9,
  book10,
];

const womanImgList = [nv1, nv2, nv3, nv4, nv5, nv6];

const imgList = [manImgList, womanImgList];

export default function Recommend() {
  const [classifyData, setClassinfyData] = useState<IClassify[]>([
    {
      key: 'male',
      title: getText('男频分类'),
      data: [],
    },
    {
      key: 'female',
      title: getText('女频分类'),
      data: [],
    },
  ]);
  useAsyncEffect(async () => {
    const [male, female] = await Promise.all([
      queryCategoryList('0'),
      queryCategoryList('1'),
    ]);
    const tempData = [...classifyData];
    tempData[0].data = male;
    tempData[1].data = female;
    setClassinfyData(tempData);
  }, []);

  const handleCategoryPage = (id: string, name: string) => {
    replacePage(
      `/classify.html?categoryId=${id}&categoryName=${encodeURIComponent(name)}`
    );
  };

  return (
    <div className={styels.classify}>
      {classifyData?.map((item, index) => {
        return (
          <section key={item.key} className={styels.section}>
            <span className={styels.header}>{item.title}</span>
            <article className={styels.article}>
              {item.data?.map((detail, i) => {
                return (
                  <div
                    onClick={() => handleCategoryPage(detail.id, detail.name)}
                    key={detail.id}
                    className={styels.bookWarp}
                  >
                    <div className={styels.bookImgWarp}>
                      <img src={imgList[index][i]} alt="" />
                    </div>
                    <div>{detail.name}</div>
                  </div>
                );
              })}
            </article>
          </section>
        );
      })}
      <section></section>
    </div>
  );
}
