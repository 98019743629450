import React from 'react';

interface State {
  error: globalThis.Error | null;
}

export default class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  State
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: globalThis.Error): void {
    // TODO 日志上报
    this.setState({ error });
  }

  render(): React.ReactNode {
    if (this.state.error) {
      // TODO 错误页面设计
      return <div>人气大爆发，请稍后再试</div>;
    }

    return this.props.children;
  }
}
