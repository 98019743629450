import { query } from '@/common/router';
import { getLocalStorage } from '@/common/storage';
import vi from './vi-language.json';

const { _tacatac_language } = query;

interface ILanguageMap {
  [key: string]: {
    [key: string]: string;
  };
}

export const languageMap: ILanguageMap = {
  vi,
  'zh-cn': {
    请输入用户名: '请输入用户名',
  },
};

let language = _tacatac_language;

export function getText(key: string): string {
  if (!language) {
    language = getLocalStorage('web-language') || 'vi';
  }
  return languageMap?.[language]?.[key] || key;
}

export function getLanguage() {
  if (!language) {
    language = getLocalStorage('web-language') || 'vi';
  }
  return language;
}
