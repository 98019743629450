import Cookies from 'js-cookie';

export const setLocalStorage = (key: string, value: any) => {
  const strValue = JSON.stringify(value);
  localStorage.setItem(key, strValue);
};

export const getLocalStorage = (key: string) => {
  try {
    const strResult = localStorage.getItem(key);
    if (!strResult) return '';
    return JSON.parse(strResult);
  } catch (e) {
    // ignore
    return '';
  }
};

export const setCookieByDomain = (key: string, value: string | object) => {
  Cookies.set(key, JSON.stringify(value), {
    secure: process.env.NODE_ENV !== 'development',
    sameSite: 'Strict',
    expires: 7,
    domain: window?.location?.hostname,
  });
};

export const getUserCookie = (key: string) => {
  try {
    const strResult = Cookies.get('userInfo');
    if (!strResult) return '';
    const data = JSON.parse(strResult);
    // 每次查询更新一波cookie时间
    // setCookieByDomain('authorInfo', data);
    return data[key];
  } catch (e) {
    // ignore
    return '';
  }
};
