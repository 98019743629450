// function throttle(func: Function, delay: number): Function {
//   let lastCall = 0;
//   return function(...args: any[]) {
//     const now = Date.now();
//     if (now - lastCall < delay) {
//       return;
//     }
//     lastCall = now;
//     return func.apply(this, args);
//   };
// }

export const getWordCount = (number: string | undefined): string => {
  let num = Number(number);

  // 检查是否成功转换
  if (isNaN(num)) {
    return '';
  }

  // 根据数字的大小格式化输出
  if (num > 1000000) {
    return `${Math.floor(num / 1000000)}M`; // 输出具体数字，保留整数位和小数位（如果有的话）
  } else if (num > 1000) {
    return `${Math.floor(num / 1000)}K`; // 输出具体数字，保留整数位和小数位（如果有的话）
  } else {
    return num.toString(); // 输出具体数字，不进行任何格式化
  }
};
