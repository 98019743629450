import React from 'react';

import { replacePage } from '@/common/router';
import { IBook } from '@/types/book';
import { getWordCount } from '@/common/util';

import styels from './index.module.less';
import { getText } from '@/common/language';

export default function VerticalBook(props: { data: IBook }) {
  const { data } = props;
  const handleBookInfo = () => {
    replacePage(`./book.html?bookId=${data?.id || data?.bookId}`);
  };
  if (!data) return null;
  const tags =
    data.tags ||
    [
      data.authorName,
      `${getWordCount(data?.wordCount)}`,
      Number(data?.visitCount) > 0 && `${getText('点击')}${data?.visitCount}`,
    ].filter(Boolean);
  return (
    <div className={styels.verticalBook} onClick={handleBookInfo}>
      <div className={styels.verticalBookImage}>
        <img src={data.picUrl} alt="img" />
      </div>
      <div className={styels.verticalBookBody}>
        <p className={styels.title}>{data.bookName}</p>
        <p
          className={styels.subTitle}
          dangerouslySetInnerHTML={{ __html: data?.bookDesc }}
        ></p>
        <div className={styels.tags}>
          {tags.map((tag) => {
            if (tag) {
              return <div key={tag}>{tag}</div>;
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}
