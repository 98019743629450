import React from 'react';
import logo from '@/static/logo.png';
import styels from './index.module.less';

export default function Logo() {
  const hanldeClick = () => {
    if (window.location.pathname !== '/home.html') {
      window.location.replace('/home.html');
    }
  };

  return (
    <div onClick={hanldeClick} className={styels.logo}>
      <img src={logo} alt="" />
    </div>
  );
}
