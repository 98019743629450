export function getQueryParams(url?: string) {
  const queryParams = {} as { [key: string]: string };
  const urlObj = new URL(url || window?.location?.href);
  const params = new URLSearchParams(urlObj.search);
  params.forEach((value, key) => {
    queryParams[key] = value;
  });
  return queryParams;
}

export function goBack() {
  window.history.back();
}

export const query = getQueryParams();

export function openNewPage(url: string, params?: { [key: string]: any }) {
  if (!url) return;
  let tempUrl = url;
  const paramsArray: string[] = query?._tacatac_language
    ? [`_tacatac_language=${query?._tacatac_language}`]
    : [];
  if (params) {
    Object.keys(params).forEach(
      (key) => params[key] && paramsArray.push(`${key}=${params[key]}`)
    );
  }

  if (paramsArray?.length) {
    if (url.search(/\?/) === -1) {
      tempUrl = `${url}?${paramsArray.join('&')}`;
    } else {
      tempUrl = `${url}&${paramsArray.join('&')}`;
    }
  }
  window.open(tempUrl);
}

export function replacePage(url: string, params?: { [key: string]: any }) {
  if (!url) return;
  let tempUrl = url;
  const paramsArray: string[] = query?._tacatac_language
    ? [`_tacatac_language=${query?._tacatac_language}`]
    : [];
  if (params) {
    Object.keys(params).forEach(
      (key) => params[key] && paramsArray.push(`${key}=${params[key]}`)
    );
  }
  if (paramsArray?.length) {
    if (url.search(/\?/) === -1) {
      tempUrl = `${url}?${paramsArray.join('&')}`;
    } else {
      tempUrl = `${url}&${paramsArray.join('&')}`;
    }
  }

  window.location.href = tempUrl;
}
