import React from 'react';
import { Tabs, Popover } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/popover';
import { EditSOutline, PayCircleOutline } from 'antd-mobile-icons';
import { replacePage } from '@/common/router';

import createPage from '@/plugin/render';
import { getText } from '@/common/language';
import Classify from './components/classify';
import Logo from '@/components/Logo';
import Suggest from './components/suggest';
import Rank from './components/rank';

import './index.less';
import Serach from '@/components/Search';

const navList = [
  {
    key: 'suggest',
    title: getText('男生'),
    Core: Suggest,
    data: {
      workDirection: '0',
    },
  },
  {
    key: 'girl',
    title: getText('女生'),
    Core: Suggest,
    data: {
      workDirection: '1',
    },
  },
  {
    key: 'classify',
    title: getText('分类'),
    Core: Classify,
    data: {},
  },
  {
    key: 'rank',
    title: getText('排行'),
    Core: Rank,
    data: {},
  },
  // {
  //   key: 'subject',
  //   title: getText('专题'),
  // },
] as {
  key: string;
  title: string;
  Core: React.FC;
  data: { [key: string]: any };
}[];

const actions: Action[] = [
  {
    key: 'submission',
    icon: <EditSOutline />,
    text: 'Hướng dẫn nộp bài',
    onClick: () => {
      replacePage('/protocol.html?id=submission');
    },
  },
  {
    key: 'welfare',
    icon: <PayCircleOutline />,
    text: 'Phúc lợi của tác giả',
    onClick: () => {
      replacePage('/protocol.html?id=welfare');
    },
  },
];

function Home() {
  return (
    <div className="page">
      <Logo />
      <Serach />
      <nav className="nav">
        <Tabs
          defaultActiveKey="suggest"
          style={{ '--content-padding': '0', '--title-font-size': '26rem' }}
        >
          {navList?.map(({ key, title, Core, data }) => {
            return (
              <Tabs.Tab title={title} key={key}>
                {Core && <Core {...data} />}
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </nav>
      <footer className="footer">
        <div className="actionWarp">
          <div
            onClick={() => {
              replacePage('/protocol.html?id=contact');
            }}
          >
            {getText('联系我们')}
          </div>
          <Popover.Menu
            actions={actions}
            mode="dark"
            placement="top"
            trigger="click"
          >
            <div>{getText('成为作者')}</div>
          </Popover.Menu>
        </div>
        <p>
          {getText(
            '本站小说作品、评论均属个人行为，不代表本站立场，如有侵权，请联系我们删除: service@tacatac.net'
          )}
        </p>
      </footer>
    </div>
  );
}

export default createPage(Home);
